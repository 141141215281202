@media (min-width: 768px) {
    .title_ellipsis {
        max-width: 50%
    }
}

@media (max-width: 768px) {
    /*    .tab_content {
            margin-top: 420px;
        }*/
    .broad_box .chat_box .chat_inner {
        height: unset;
    }

    /*.broad_box .chat_box {*/
    /*    padding-bottom: 24px*/
    /*}*/
}


/**
iOS Safari에서 하단 주소창 올라왔을때 스크롤 추가로 생김 방지
 */
@supports (-webkit-touch-callout: none) {
    body:has(.video-tab) {
        height: -webkit-fill-available;
        overflow: hidden;
    }
}

input[type=checkbox].chip:checked + label {
    background: black;
    color: white !important;
}

@media (max-width: 768px) {
    .player_placeholder {
        padding-bottom: 47.5%;
    }

    body {
        /*overflow: hidden;*/
    }

    .broad_thumb {
        height: calc(100vh - 64px - 68px);
        display: flex;
        flex-direction: column;
    }

    .broad_thumb.no_nav {
        height: calc(100vh - 64px);
    }
}

@media (max-width: 640px) {
    .player_placeholder {
        padding-bottom: 56.25%;
    }
    .embedPlayer {
        height: 205px !important;
    }
}

.tab_content {
    overflow: auto !important;
}

.chat.overflow-hidden {
    height: 100%;
}

