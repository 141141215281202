@media (max-width: 768px) {
    .broad_box {
        display: block !important;
    }
    .rel_box {
        display: block !important;
    }
}

.button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 16px;
    padding: 6px 16px;
    margin: 4px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
}

.MuiFormControl-root input {
    margin-top: 0;
    padding-top: 24px;
    background-color: unset;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    height: 40px;
}

.button.selected {
    background-color: #000000;
    color: white;
}

.button:hover {
    background-color: #e0e0e0;
}

.tab-menu {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.expanding-box {
    display: none;
    flex-direction: row;
    position: absolute;
    top: 0;
    right: 100%;
    align-items: flex-end;
    background-color: unset;
    border: 0;
    border-radius: 8px;
    box-shadow: unset;
    padding: 0px;
    z-index: 1000;
    white-space: nowrap;
    min-width: 320px; /* DatePicker의 최소 너비 */
    min-height: 40px; /* DatePicker의 최소 높이 */
}

.expanding-box.visible {
    display: flex;
}

.expanding-box.invisible {
    display: none;
}

.datepicker {
    width: 160px; /* DatePicker 너비 설정 */
    height: 2.6em; /* 원하는 높이로 변경 */
    display: flex-end;
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .expanding-box {
        display: flex;
        flex-direction: column;
    }
    .datepicker {
        height: 2.2rem;
        font-size: 8;
    }
}
