.chip {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    height: 40px;
    border: 1px solid #333333;
    border-radius: 999px;
    font-size: 14px;
    font-weight: 400;
}

.chip.sm {
    padding: 0px 10px;
    height: 30px;
}

.chip[data-checked=true] {
    background: black !important;
    color: white !important;
}

.chip.collapsable {
    padding: 0px 18px 0 24px;
}