.info_icon label {
    display: flex;
    height: 16px;
    font-size: 12px;
    font-weight: 300;
    background: url(../../../../public/images/ico_comment.png) no-repeat;
    padding-left: 20px;
}

.info_icon label.share {
    background: url(../../../../public/images/ico_share.png) no-repeat;
}

.info_icon label.like {
    background: url(../../../../public/images/ico_good.png) no-repeat;
}

.info_icon label.like[aria-checked=true] {
    background: url(../../../../public/images/ico_good_active.png) no-repeat;
}

.info_icon label.view {
    background: url(../../../../public/images/ico_view.png) no-repeat;
}

.pointer {
    cursor: pointer
}