.select-modal {
    display: none;
}

.select-modal li {
    cursor: pointer;
    border-bottom: 1px solid #eaeaea !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
}

.select-modal li:last-child {
    border-bottom: unset !important;
}

@media (min-width: 768px) {
    .select-modal.active {
        background: #fff;
        border: 1px solid #eaeaea;
        width: 106px;
        border-radius: 8px;
        display: block;
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 10;
        box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.04);
    }
}

@media (max-width: 767px) {
    .select-modal.active {
        all: unset;
        position: fixed;
        left: 0;
        bottom: 0;
        content: "";
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.4);
        z-index: 192929;
    }

    .select-modal .sheet_inner {
        padding: 32px 18px;
        background: #fff;
        border-radius: 20px 20px 0 0;
    }

    .select-modal li:last-child {
        border-bottom: unset !important;
    }

    .select-modal li:hover {
        background: #f2f2f2;
        transition: .3s;
    }
}

.video-option-modal {

}

.video-option-modal .title {
    font-family: 'Pretendard', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
}

.video-option-modal .close-wrap {
   padding: 5px;
}

.video-option-modal .close {
    width: 12px;
    height: 12px;
}

.video-option-modal .item {
    padding: 13px 0;
}

.video-option-modal .item_title {
    font-family: 'Pretendard', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.video-option-modal .item_value {
    font-family: 'Pretendard', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}