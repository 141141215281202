.broad_box .chat_box .chat_inner {
    padding: 0.75rem 24px 0 24px
}

.chat_filed {
    z-index: 0 !important;
}

.broad_box .chat_box .chat .submit_btn[disabled] {
    background: #eee;
    color: black;
}

.broad_box .chat_box .chat img.setting {
    display: none
}

.broad_box .chat_box .chat li:hover img.setting {
    display: inline;
}

.chat_box .chat .chat_inner > li:hover:after {
    all: unset !important;
}


.broad_box .chat_box .chat .submit_btn {
    background: #008cdc;
    color: #fff;
}

.broad_box.chat_height {
    height: unset;
}

@media (max-width: 768px) {
    .broad_box.chat_height {
        height: 100%;
    }
}

.broad_box .chat .chat_height {
    /*height: unset;*/
}

@media (max-width: 768px) {
    .broad_box .chat .chat_height {
        height: 100% !important;
        overflow-y: scroll !important;
    }
}


.chat_tab {
    /*height: unset;*/
}

@media (max-width: 768px) {
    .chat_tab {
        height: 100% !important;
    }
}