.popup_join .text_filed {
    min-height: 60px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid #eaeaea;
    background: #fff;
    padding: 20px 16px;
    color: #aaa;
    font-weight: 300;
}


.popup_join .code[aria-expanded=true]:after {
    transform: rotate(315deg);
    top: 8px
}.popup_join .code:after {
     top: 4px
 }